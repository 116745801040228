<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterAgencies"
      @clear="clearFilter"
      currentComponent="agencies-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>{{ item.company_name }}</td>
        <td>{{ item.trading_name }}</td>
        <td>{{ item.name }}</td>
        <td class="text-center">{{ item.email }}</td>
        <td class="text-center">{{ item.contact }}</td>
        <td class="text-center">{{ item.seller }}</td>
        <td class="text-right">
          <StatusChip :currentStatus="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    isFilterOpened: false,
    dataTable: {
      title: 'Agências',
      new: 'agencies-new',
      edit: 'agencies-edit',
      context: 'agencies-context',
      massAction: 'agencies-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Razão Social', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'trading_name' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'contact', align: 'center' },
        { text: 'Vendedor', value: 'seller', align: 'center' },
        { text: 'Status', value: 'status', align: 'end' },
      ],
    },
  }),
  methods: {
    getAgencies() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/agencies', {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterAgencies(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/agency', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getAgencies()
    },
  },
  mounted() {
    this.getAgencies()

    contextMenu.$once('action', (type) => {
      if (type === 'agencies') {
        this.setInitialData()
      }
    })
    massAction.$once('action', () => {
      this.setInitialData()
    })
  },
}
</script>
